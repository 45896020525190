import { config } from '@/config';
import * as experimentConstants from '@/constants/experimentConstants';
import { isServer, isSistaminuten } from '@/helpers';
import { _s } from '@/locale';
import { NavItem, NavItemIdentifier } from '@/types/navigation';
import { User } from '@/types/user';
import { Variants } from '@amplitude/experiment-js-client';
import { APP_ROUTES } from './pages';

const tBase = (key: string) => _s(`headerNavigation.${key}`);

const navSearchItem: NavItem = {
  id: 'nav-item-search',
  icon: 'search',
  label: tBase('search'),
  to: '/search',
  identifier: NavItemIdentifier.Search,
};

const navBookinsItem: NavItem = {
  id: 'nav-item-bookings',
  icon: 'calendar-empty',
  label: tBase('bookings'),
  to: '/bokningar',
  identifier: NavItemIdentifier.Bookings,
};

const sistaminutenItem: NavItem = {
  id: 'nav-item-sistaminuten',
  icon: 'smt',
  label: tBase('sistaminuten'),
  to: '/sistaminuten',
  identifier: NavItemIdentifier.Sistaminuten,
};

const navDealsItem: NavItem = {
  id: 'nav-item-deals',
  icon: 'deal',
  label: 'Deals',
  to: '/deals',
  identifier: NavItemIdentifier.Deals,
};

const navFavoritesItem: NavItem = {
  id: 'nav-item-favorites',
  icon: 'heart',
  label: tBase('favorites'),
  to: '/favoriter',
  identifier: NavItemIdentifier.Favorites,
};

const navCancelBookingItem: NavItem = {
  icon: 'calendar-cross',
  label: tBase('cancel'),
  to: '/cancel',
  identifier: NavItemIdentifier.Cancel,
};

const navConnectCompanyItem: NavItem = {
  icon: 'company',
  label: tBase('connectCompany'),
  to: 'https://business.bokadirekt.se/',
  identifier: NavItemIdentifier.ConnectCompany,
  breakpoint: 'min-[1280px]:inline-flex',
};

const navManageCompanyItem: NavItem = {
  icon: 'company',
  label: tBase('manageCompany'),
  to: config.merchantUrl,
  identifier: NavItemIdentifier.CompanyLogin,
  breakpoint: 'xl:inline-flex',
};

const navLoginItem: NavItem = {
  icon: 'account',
  label: tBase('login'),
  identifier: NavItemIdentifier.LogIn,
};

export const navHamburgerItem: NavItem = {
  id: 'nav-item-menu',
  icon: 'menu',
  identifier: NavItemIdentifier.Hamburger,
};

const navWellnessBuyItem: NavItem = {
  icon: 'wellness-card',
  label: tBase('buyWellnessCard'),
  to: APP_ROUTES.WELLNESSCARD_CHECKOUT,
  identifier: NavItemIdentifier.WellnessCardBuy,
  breakpoint: 'xl:inline-flex',
};

const navGiftCardBuyItem: NavItem = {
  icon: 'gift-card',
  label: tBase('buyGiftCard'),
  to: APP_ROUTES.GIFTCARD_CHECKOUT,
  identifier: NavItemIdentifier.GiftCardBuy,
  breakpoint: 'min-[940px]:inline-flex',
};

const navBarMainNavigationBokadirektItems = (isMerchant: boolean, whitelabel: boolean, flags: Variants): NavItem[] => {
  const dealsFeature = flags?.[experimentConstants.DEALS_FEATURE_FLAG]?.value === 'on';

  const giftcardBuyItem = { ...navGiftCardBuyItem };
  const connectCompanyItem = { ...navConnectCompanyItem };

  return [
    navSearchItem,
    navBookinsItem,
    sistaminutenItem,
    ...(dealsFeature ? [navDealsItem] : []),
    navFavoritesItem,
    ...(!whitelabel ? ([navWellnessBuyItem, giftcardBuyItem] as NavItem[]) : []),
    ...(!whitelabel ? ([...(isMerchant ? [navManageCompanyItem] : [connectCompanyItem])] as NavItem[]) : []),
  ].filter(Boolean);
};

const navBarMainNavigationSistaminutenItems: NavItem[] = [
  navSearchItem,
  navCancelBookingItem,
  navConnectCompanyItem,
  navHamburgerItem,
];

export const navBarMainNavigationItems = (user: User, flags: Variants): NavItem[] => {
  const whitelabel = !isServer ? sessionStorage.getItem('whitelabel') === 'true' : false;
  const isMerchant = user?.about?.isMerchant;

  let items: NavItem[] = isSistaminuten()
    ? [...navBarMainNavigationSistaminutenItems]
    : [
        ...navBarMainNavigationBokadirektItems(isMerchant, whitelabel, flags),
        ...(user ? [navHamburgerItem] : [navLoginItem, navHamburgerItem]),
      ];

  return items.filter(Boolean);
};
