import Icon from '@/components/icons/Icon';
import Modal from '@/components/modules/modals/redesign/Modal/Modal';
import { ReadMore } from '@/components/modules/readmore';
import useMobileView from '@/hooks/useMobileView';
import { _s } from '@/locale';
import { PLACE_IMPRESSION, placeService } from '@/services';
import { useState } from 'react';
import Card from '../../../../components/elements/Card/Card';
import { LinkButton } from '../../../../components/elements/forms/buttons';
import Symbol from '../../../../components/elements/Symbol';

type ServiceCardProps = {
  name: string;
  description?: string;
  length?: number;
  price?: number;
  discount?: number;
  externalUrl?: string;
  placeId: number;
};

const ServiceCard = ({ name, description, length, price, discount, externalUrl, placeId }: ServiceCardProps) => {
  const finalPrice = discount ? price - discount : price;
  const { isMobileView } = useMobileView();
  const [showReadMore, setShowReadMore] = useState<boolean>(false);

  const handleExternalWebsiteClick = () => {
    placeService.storePlaceImpressions([placeId], PLACE_IMPRESSION.PLACE_EXTERNAL_BUSINESS_LINK_CLICK);
  };

  const handleClickReadMore = () => {
    setShowReadMore((prev) => !prev);
  };

  const handleClickClose = () => {
    setShowReadMore(false);
  };

  return (
    <>
      <Modal className={`h-screen`} isOpen={isMobileView && showReadMore}>
        <div className={`flex h-full w-full flex-col`}>
          <Modal.NewHeader title={name} onClose={handleClickClose} />
          <div className={`p-xl bg-surface_l4 h-full`}>{description}</div>
        </div>
      </Modal>

      <Card size="sm">
        <div className={`gap-sm flex ${isMobileView ? 'flex-col' : ''}`}>
          <div className="flex flex-1 items-center justify-between">
            <div className="gap-sm flex flex-1 items-start overflow-hidden">
              <Symbol size="md" children={<Icon variant="tag" color="fg_secondary" />} />
              <div className="gap-2xs flex flex-col overflow-hidden">
                <h2 className="text-l text-fg_primary">{name}</h2>
                <div className="text-s gap-2xs flex items-start">
                  {!isMobileView && length && (
                    <>
                      <span className="whitespace-nowrap font-bold">{length + ' ' + _s('min')}</span>{' '}
                    </>
                  )}
                  <div
                    className={`text-fg_secondary ${
                      !isMobileView && description
                        ? 'before:w-xxs before:h-xxs before:mr-2xs before:bg-fg_tertiary before:mt-[5px] before:inline-block before:rounded-full before:content-[""]'
                        : 'w-full'
                    } flex items-start`}>
                    {isMobileView && <span className="truncate">{description}</span>}
                    {!isMobileView && <ReadMore className="text-s" maxLines={1} text={description} />}
                  </div>
                </div>
              </div>
            </div>
            {isMobileView && (
              <button className="outline-none" onClick={handleClickReadMore}>
                <Icon variant="info-circle" style={{ width: '20px', height: '20px' }} color="fg_link" />
              </button>
            )}
          </div>
          <div className={`border-border_regular ${isMobileView ? 'border-t' : 'border-l'}`}>
            <div className={`text-s gap-sm flex ${isMobileView ? 'pt-sm justify-between' : 'pl-sm pt-xs'}`}>
              <div className="gap-2xs flex flex-col justify-center">
                <div className={`flex ${isMobileView ? 'gap-xs' : 'gap-2xs flex-col'} items-center justify-center`}>
                  {Boolean(discount) && isMobileView && (
                    <span className="text-m text-fg_highlight font-bold">{price - discount + ' ' + _s('kr')}</span>
                  )}
                  <span className={`${Boolean(discount) ? 'text-fg_tertiary line-through' : ' font-bold'}`}>
                    {finalPrice + ' ' + _s('kr')}
                  </span>
                  {Boolean(discount) && !isMobileView && (
                    <span className="text-m text-fg_highlight font-bold">{price - discount + ' ' + _s('kr')}</span>
                  )}
                </div>
                {isMobileView && length && (
                  <>
                    <span className="text-fg_secondary whitespace-nowrap font-bold">{length + ' ' + _s('min')}</span>{' '}
                  </>
                )}
              </div>

              <LinkButton
                variant="primary"
                size="sm"
                label={_s('book')}
                iconNoFilter
                to={externalUrl}
                target="_blank"
                rel="noreferrer"
                onClick={handleExternalWebsiteClick}
                rightIcon={<Icon variant="external-link" color="fg_primary_inverse" />}
              />
            </div>
          </div>
        </div>
      </Card>
    </>
  );
};

export default ServiceCard;
